import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Select,
  Checkbox,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  FormHelperText,
  Textarea,
  Divider,
  Spacer,
  Text,
  Button,
  Collapse,
  Flex,
  useToast,
  HStack,
  VStack,
} from "@chakra-ui/react"
import { EmailIcon, LockIcon } from "@chakra-ui/icons"
import React, { useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { navigate } from "gatsby"

async function submitSignup(
  firstName,
  lastName,
  email,
  feature,
  feedback,
  subscribed
) {
  const serverResponse = await axios.post("/.netlify/functions/signup", {
    firstName: firstName,
    lastName: lastName,
    email: email,
    feature: feature,
    feedback: feedback,
    subscribed: subscribed,
  })
  const sendEmail = await axios.post("/.netlify/functions/send-email", {
    email: email,
  })
  // console.log(serverResponse, sendEmail)
}

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  // password: Yup.string().required("Required").min(8),
})

export default function SignupForm({ height }) {
  // const [addSubmission, { data, loading, error }] = useMutation(ADD_SUBMISSION)
  // const [createUser, { data: userData, loading, error }] = useMutation(ADD_USER)
  const [checked, setChecked] = useState(false)
  const toast = useToast()
  const handleCheckToggle = () => setChecked(!checked)

  return (
    <VStack
      bg="white"
      borderRadius="sm"
      m={[0, 0, 0, 0, 10]}
      w={["100%", 500]}
      p={[4, 6, 8, 10]}
      boxShadow="rgba(255, 255, 255, 0.2) 0px 4px 12px"
      color="black"
    >
      <Heading textAlign="center" size="md" m={0} mb={2} p={0}>
        Sign up to be the first to receive news and exclusive offers.
      </Heading>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          likedFeature: "",
          feedback: "",
          subscribed: true,
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          submitSignup(
            values.firstName,
            values.lastName,
            values.email,
            values.likedFeature,
            values.feedback,
            values.subscribed
          )
            .then((e) => actions.setSubmitting(false))
            .then((e) =>
              toast({
                title: "Sign up confirmed. Thank you for your feedback.",
                status: "success",
                isClosable: true,
                duration: 3000,
              })
            )
            .then((e) => navigate("/"))
            .catch((e) => {
              actions.setSubmitting(false)
              actions.setFieldError(
                "email",
                "This email was already registered."
              )
              console.log(e)
            })
        }}
      >
        {(props) => (
          <Form>
            <HStack mb={[2, 4]}>
              <Field name="firstName">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.firstName && form.touched.firstName}
                    isRequired
                  >
                    <FormLabel htmlFor="firstName" fontSize="sm">
                      First Name
                    </FormLabel>
                    <InputGroup>
                      <Input
                        {...field}
                        id="firstName"
                        isRequired
                        variant="filled"
                        size="md"
                        placeholder="First Name"
                      />
                    </InputGroup>
                    <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="lastName">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.lastName && form.touched.lastName}
                    isRequired
                  >
                    <FormLabel htmlFor="lastName" fontSize="sm">
                      Last Name
                    </FormLabel>
                    <InputGroup>
                      <Input
                        {...field}
                        id="lastName"
                        isRequired
                        variant="filled"
                        size="md"
                        placeholder="Last Name"
                      />
                    </InputGroup>
                    <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </HStack>
            <Field name="email">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                  isRequired
                >
                  <FormLabel htmlFor="email" fontSize="sm">
                    Email
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<EmailIcon color="black.300" />}
                    />
                    <Input
                      {...field}
                      id="email"
                      isRequired
                      variant="filled"
                      size="md"
                      type="email"
                      placeholder="Email"
                    />
                  </InputGroup>
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Box pt={4} pb={4}>
              <Divider variant="solid" border="3px solid black" />
              <Heading size="sm">
                This section is optional but we'd love to hear your feedback!
              </Heading>
              <Field name="feedback">
                {({ field, form }) => (
                  <Textarea
                    {...field}
                    variant="filled"
                    boxShadow="sm"
                    mt={0}
                    borderRadius="sm"
                    placeholder="Feel free to say anything. We won't judge."
                    size="md"
                    isFullWidth
                  />
                )}
              </Field>
              <Heading size="xs">
                What feature are you most excited about?
              </Heading>
              <Field name="likedFeature">
                {({ field, form }) => (
                  <Select
                    {...field}
                    variant="filled"
                    mt={0}
                    placeholder="Select option"
                  >
                    <option value="Track All Donations">
                      Being able to track all of my donations in one place.
                    </option>
                    <option value="Portfolio of Nonprofits">
                      Creating my own portfolio of nonprofits so I can give to
                      them whenever I want.
                    </option>
                    <option value="Impact of Donations">
                      Having the ability to view the impact of my donations.
                    </option>
                    <option value="More Effective Donation">
                      Being able to more effectively donate through data.
                    </option>
                    <option value="Other">Other. Please describe above.</option>
                  </Select>
                )}
              </Field>
            </Box>
            <Field name="subscribed">
              {({ field, form }) => (
                <Checkbox
                  {...field}
                  size="md"
                  mt={2}
                  textAlign="left"
                  justifySelf="left"
                  alignSelf="left"
                  colorScheme="blue"
                >
                  I want to be kept up to date!
                </Checkbox>
              )}
            </Field>
            <Spacer />
            <Button
              _hover={{ bg: "#191919", color: "white" }}
              mt={6}
              mb={6}
              variant="outline"
              width="100%"
              size="lg"
              colorScheme="black"
              type="submit"
              isLoading={props.isSubmitting}
            >
              Sign Up
            </Button>
          </Form>
        )}
      </Formik>
    </VStack>
  )
}
