import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Divider } from "@chakra-ui/react"

import { Container } from "../global"

const Footer = () => (
  <FooterWrapper id="more resources">
    <FooterColumnContainer>
      {/* <FooterColumn>
        <span>Resources</span>
        <ul>
          <Wip relativeUrl="../../register">Contact Us</Wip>
          <Wip>Our Blog</Wip>
          <Wip>Our API</Wip>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Company</span>
        <ul>
          <Wip relativeUrl="../../faq">About Us</Wip>
          <Wip>Careers - We're Hiring</Wip>
          <Wip>Our Core Values</Wip>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Social</span>
        <ul>
          <Wip>LinkedIn</Wip>
          <Wip>Instagram</Wip>
          <Wip>Facebook</Wip>
          <Wip>Twitter</Wip>
        </ul>
      </FooterColumn> */}
    </FooterColumnContainer>
    <BrandContainer>My Immortality Project (2021)</BrandContainer>
  </FooterWrapper>
)

function Wip({ children, relativeUrl }) {
  return (
    <Link
      to={relativeUrl || "../../construction"}
      style={{ textDecoration: "none" }}
    >
      <li>{children}</li>
    </Link>
  )
}

const FooterWrapper = styled.footer`
  background-color: whitesmoke;
  margin: 0px 0 0;
  padding: 40px 0 80px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 48px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
  }
`
const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`
const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${(props) => props.theme.font.bold};
    color: ${(props) => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${(props) => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${(props) => props.theme.font.normal};
      font-size: 15px;
    }
  }
`

export default Footer
