import React from "react"
import styled, { keyframes } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FiChevronDown } from "react-icons/fi"
import {
  Center,
  Flex,
  Heading,
  Text,
  Box,
  Button,
  SimpleGrid,
  VStack,
  Grid,
  Checkbox,
  useMediaQuery,
} from "@chakra-ui/react"
import Lottie from "react-lottie"
import animationData from "../../images/dashperson.json"

export default function FeatureSet(props) {
  if (props.isSingle) {
    return (
      <>
        <FeatureContainer
          columns={[1]}
          gap="10"
          bg={props.bg}
          color={props.color}
        >
          <Box
            padding={{ md: "2em" }}
            textAlign="left"
            alignSelf="center"
            fontWeight="light"
          >
            {props.child1}
          </Box>
        </FeatureContainer>
      </>
    )
  } else {
    return (
      <FeatureContainer
        columns={[1, 2]}
        gap="10"
        bg={props.bg}
        color={props.color}
      >
        <Box
          padding={{ md: "2em" }}
          textAlign="left"
          alignSelf="center"
          fontWeight="light"
        >
          {props.child1}
        </Box>
        <Box textAlign="left" alignSelf="center" padding={{ md: "2em" }}>
          {props.child2}
        </Box>
      </FeatureContainer>
    )
  }
}

const FeatureContainer = styled(SimpleGrid)`
  padding: 2em;
`
