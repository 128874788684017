import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Select,
  Checkbox,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  FormHelperText,
  Textarea,
  Divider,
  Spacer,
  HStack,
  Text,
  Tag,
  Image,
  VStack,
  Button,
  Collapse,
  Flex,
  Badge,
  AspectRatio,
  useToast,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react"
import { EmailIcon } from "@chakra-ui/icons"
import React, { useEffect, useState } from "react"

export default function FeatureCard(props) {
  return (
    <Flex justify="flex-start" flexDir="column">
      <Heading size="3xl" textAlign="center">
        {props.title}
      </Heading>
      <Text fontFamily="Helvetica" fontSize="lg" fontWeight="normal">
        {props.desc}
      </Text>
    </Flex>
  )
}
