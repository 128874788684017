import React, { useState } from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"
import {
  Center,
  Square,
  Circle,
  Heading,
  Divider,
  Grid,
  Text,
  Box,
  Flex,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Spacer,
  AccordionIcon,
  SimpleGrid,
  VStack,
  HStack,
} from "@chakra-ui/react"
import hands from "../images/product/hands.jpg"
import SignupForm from "../components/cards/SignupForm"
import FeatureCard from "../components/cards/FeatureCard"
import FeatureSet from "../components/sections/featureSet"
import { CheckIcon } from "@chakra-ui/icons"
import { CheckCircle } from "react-feather"

function ContactPage() {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <SimpleGrid bg="blackAlpha.800" columns={[1, 1, 2]}>
        <Highlights />
        <Flex justify="center" align="center">
          <SignupForm />
        </Flex>
      </SimpleGrid>
    </Layout>
  )
}

function HighlightCard({ title, desc }) {
  return (
    <VStack
      bg="transparent"
      maxW="90%"
      minH="100%"
      // boxShadow="md"
      borderRadius="md"
    >
      <HStack alignSelf="flex-start">
        <CheckCircle color="green" />
        <Spacer />
        <Heading size="sm" textAlign="left">
          {title}
        </Heading>
      </HStack>
      <Text fontSize="sm" textAlign="left" maxW="100%">
        {desc}
      </Text>
    </VStack>
  )
}

function Highlights() {
  return (
    <Center bg="whitesmoke" p={2} minHeight="100vh">
      <VStack
        spacing={[6, 7, 8]}
        display="flex"
        flexDirection="column"
        justify="flex-start"
        align="flex-start"
        px={[6, 8, 10]}
      >
        <Heading size="md" alignSelf="left">
          My Immortality Project
        </Heading>
        <HighlightCard
          title="Find your cause and build a legacy."
          desc="Donate to all of your favorite charities, all at once, and in one
            tap. Personalize your own brand of philanthropy and share it with
            the world."
        />
        <HighlightCard
          title="The easiest way to give."
          desc="Automatically donate x% of every paycheck into your
          personal cause. Give to multiple charities and see all your impact
          and communication in one place instead of searching email for hours."
        />
        <HighlightCard
          title="Inherently Social."
          desc="Follow and subscribe to the causes of your favorite streamers,
          influencers, and celebrities. As an influencer, share your dream for
          the world with all your followers."
        />
        <HighlightCard
          title="Data Driven. Built for Impact."
          desc="Research and make smarter decisions in the same place where you
          donate. See key metrics rolled up on a 'cause' level and create
          global change knowing that your money is being used well."
        />
      </VStack>
    </Center>
  )
}

export default ContactPage
